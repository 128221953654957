<template>
  <div class="trasaction-result">
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Confirm register</h3>
            </div>
            <div class="card-body min-vh" v-loading="loading">
              <div class="row justify-content-md-center">
                <div class="col-lg-8 col-md-12">
                  <div>
                    <el-result
                      v-if="result"
                      icon="success"
                      title="Register complete"
                    />
                    <el-result v-else icon="error" :title="`Register fail`" />
                  </div>
                  <div class="text-center">
                    <el-button
                      type="primary"
                      icon="el-icon-arrow-left"
                      @click="gotoApp"
                    >
                      Open A&B Plus App
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountApi from "@/api/wallet/account";
export default {
  data() { 
    return {
      loading: false,
      result: false
    }
  },
  methods: {
    gotoApp() {
      const deeplink = `abplus://abfx`;
      window.location.href = deeplink;
    },
    confirmRegister() { 
      this.loading = true;
      AccountApi.confirmRegister(this.$route.query.uid).then(({ result, message }) => { 
        this.loading = false;
        if (result) { 
          this.result = true;
        }
      })
    }
  },
  mounted() {
    this.confirmRegister();
  },
};
</script>

<style>
</style>